import Mainpage from "./page/Mainpage";

function App() {
  return (
    <div>
      <Mainpage />
    </div>
  );
}

export default App;
